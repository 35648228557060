// MEMO: 主に汎用的に使うメッセージを定義する
const generalGetErrorMessage = 'データ取得時にエラーが発生しました'
const generalGetReportErrorMessage = 'レポートの取得時にエラーが発生しました'
const generalRequestErrorMessage = 'データの送信時にエラーが発生しました'
const generalForbiddenErrorMessage = '権限がありません'

const loginErrorMessage =
  'ログインに失敗しました。IDとパスワードを確認してください'
const backendValidationErrorMessage = '入力内容に誤りがあります'
const logoutMessage = 'ログアウトしました'
const logoutErrorMessage = 'ログアウトに失敗しました'
const emailDuplicationErrorMessage = 'このメールアドレスは使用できません'

const PasskeyRegistrationErrorMessage =
  'パスキーの登録中にエラーが発生しました。'

const OTPMailSendErrorMessage = '認証コードのメール送信に失敗しました'
const OTPErrorMessage =
  'ワンタイムパスワードの認証に失敗しました。認証コードを確認してください'
const OTPLimitExceededErrorMessage = 'ワンタイムパスワードの試行回数を超えました。もう一度ログインからやり直してください'

export {
  generalGetErrorMessage,
  generalGetReportErrorMessage,
  generalRequestErrorMessage,
  generalForbiddenErrorMessage,
  loginErrorMessage,
  backendValidationErrorMessage,
  logoutMessage,
  logoutErrorMessage,
  emailDuplicationErrorMessage,
  PasskeyRegistrationErrorMessage,
  OTPMailSendErrorMessage,
  OTPErrorMessage,
  OTPLimitExceededErrorMessage,
}
